<template>
    <div class="item" @click="openTag">
        <div class="ring">
            <div class="avatar"></div>
        </div>
        <h5 class="tag">{{tag.Tag}}</h5>
    </div>
</template>

<script>
    export default {
        name: "CircleTagItem",
        props: {
            tag: Object
        },
        data() {
            return {
                image: require('@/assets/img/placeholders/category-placeholder.png')
            }
        },
        methods: {
            openTag() {
                this.$router.push({
                    name: 'Stores',
                    query: {
                        'tag': this.tag.Tag
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .item {
        width: 90px;
        text-align: center;
        padding: 14px 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .item:first-child {
        padding-left: 21px;
    }

    .item:last-child {
        padding-right: 21px;
    }

    .tag {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #1d1d1d;
    }

    .ring {
        width: 64px;
        height: 64px;
        border-radius: 32px;

        border: 3px solid rgba(203, 153, 126, 0.3);
        background: #e2e8f0;

        cursor: pointer;
        transition: All 0.3s ease;
        overflow: hidden;
    }

    .ring:hover {
        /* This is a very simple trick to animation a ring of colors, you should go find a library for this*/
        transform: rotate(9deg) scale(1.05) translate(1px);
        animation: colors 1s ease infinite;
        background-size: 200% 200%;
        background-color: #663dff;
        border: 4px solid transparent;
        animation-direction: alternate;
        background-image: linear-gradient(319deg, #7d5fee 0%, #b72bff 33%, #ff2eb0 66%, #7eee40 100%);
    }

    @keyframes colors {
        0% {
            background-position: 10% 0
        }
        100% {
            background-position: 91% 100%
        }
    }

    .avatar {
        background: url('/src/assets/img/placeholders/category-placeholder.png');
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: 100%;
        width: 100%;
    }

</style>

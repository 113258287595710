import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {Storage} from "@/services/Storage";

Vue.use(Vuex);

function storeKey(id) {
    return 'store' + id
}

export default {
    name: "Cart",
    state: {
        carts: [],
        currentCart: undefined,
    },
    getters: {
        getAllCarts: (state) => state.carts,

        getCartCount: (state) => state.carts.length,

        getCurrentCart: (state) => state.currentCart,

        getCartItems: (state) => state.currentCart ? state.currentCart.items : [],
    },
    mutations: {
        setCarts: (state, payload) => {
            if (payload){
                return Storage.setCarts(payload).then(() => state.carts = payload);
            }
            return Storage.getAllCarts().then((res) =>state.carts = res);
        },

        setCurrentCart: (state, payload) => {
            if (typeof payload === 'object') {
                if (state.carts.length === 0) {
                    state.carts.push(payload)
                }
                state.currentCart = payload
                state.carts = state.carts.map(c => {
                    if (c.id === payload.id) {
                        return payload;
                    }
                    return c;
                })
            } else {
                return Storage.getAllCarts()
                    .then((res) => {
                        state.carts = res;
                        state.currentCart = undefined;
                    });
            }
        }

    },
    actions: {
        createCart: (context, storeId) => {
            const userId = localStorage.getItem('user');
            let obj = {
                store: storeId,
                session: localStorage.getItem('sessionID'),
                user: (userId) ? Number(userId) : 0,
            }
            return axios.post(ApiServer + 'cart/create', obj)
                .then((res) => {
                    if (res.status === 201) {
                        context.commit("setCurrentCart", res.data)
                        return res.data;
                    }
                }).catch((err) => responses.throwErr(err));
        },

        addToCart: async (context, itemObj) => {
            let item = {
                plu: itemObj.plu,
                qty: itemObj.qty,
                store_id: itemObj.storeId,
                cartid: 0
            }
            return context.dispatch("getStoreCart", itemObj.storeId).then((res) => {
                if (res.status === 204) {
                    context.dispatch("createCart", itemObj.storeId).then((resn) => {
                        item.cartid = resn.id;
                        context.dispatch('addItem', item);
                    })
                } else if (res.status === 200) {
                    item.cartid = res.data.id
                    context.dispatch('addItem', item);
                }
            })
        },

        getStoreCart: (context, data) => {
            let userId = localStorage.getItem('user');
            return axios.post(ApiServer + 'cart/getStore', {
                store: data,
                user: (userId) ? Number(userId) : 0,
                session: localStorage.getItem(`sessionID`)
            }).then((res) =>res)
                .catch(err => responses.throwErr(err))
        },

        getSessionCarts: ({commit}) => {
            let userId = localStorage.getItem('user');
            return axios.post(ApiServer + 'cart/session', {
                user_id: (userId) ? Number(userId) : 0,
                session_id: localStorage.getItem(`sessionID`),
            }).then(({data}) => commit("setCarts", data))
                .catch(err => responses.throwErr(err))
        },

        addItem(context, data) {
            return axios.post(ApiServer + `cart/additem/${data.cartid}`, data).then((res) => {
                if (res.status === 201) {
                    context.commit("setCurrentCart", res.data)
                    context.dispatch('getSessionCarts');
                    return res.data;
                }
            }).catch((err) => responses.throwErr(err));
        },

        removeItem(context, data) {
            return axios.delete(ApiServer + `cart/deleteItem/${data.cart_id}/${data.id}`)
                .then((res) => {
                    if (res.status === 202) {
                        context.commit("setCurrentCart", res.data)
                        context.dispatch('getSessionCarts');
                        return res;
                    }
                }).catch((err) => responses.throwErr(err));
        },


        updateItem(context, data) {
            return axios.post(ApiServer + `cart/editItem/${data.cart_id}`, data)
                .then((res) => {
                    if (res.status === 202) {
                        context.commit("setCurrentCart", res.data)
                        context.dispatch('getSessionCarts');
                        return res;
                    }
                }).catch((err) => responses.throwErr(err));
        },
    }
}

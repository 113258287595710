<template>
  <div class="store-body" v-intersect="onIntersect">
    <div class="assert-body">
      <div class="store-banner">
        <NwImg :src="bannerUrl"  alt="Store Banner" class="banner-img" />
        <div @click="openStore" class="store-overlay"></div>
      </div>
    </div>
    <div @click="openStore" class="store-details">
      <h4 class="store-name">{{ store.StoreName }}</h4>
      <div class="store-address">
        <p class="category" v-if="getStoreTags.length > 0">
          <i class="category-icon fa fa-hashtag"> </i> {{ getStoreTags }}
        </p>
        <p class="address">
          <i class="category-icon fa fa-street-view"> </i> {{ getAddress }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShopItem",
  props: {
    store: Object
  },
  computed: {
    bannerUrl(){
      return $utils.getStoreBannerUrl(this.store.id);
    },
    logoUrl() {
      return $utils.getStoreLogoUrl(this.store.id);
    },
    getAddress() {
      return this.store.storeLocation.Address;
    },
    getStoreTags() {
      return this.store.Tags.map(t => t.Tag).join(', ')
    },
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      favourite: false,
      store_banner: {
        banner: undefined
      },
      cart_qty: "0",
      store_logo: '',
      loaded: false,
    }
  },
  methods: {
    openStore() {
      this.$store.commit("setCurrentStore", this.store)
      return this.$router.push({
        name: 'Shop',
        params: {
          id: this.store.id
        }
      })
    },
    onIntersect(entries, observer) {
      //if (!this.loaded) this.getAsserts()
    },
    openCart(id) {
      this.$store.commit("setCurrentStore", this.store)
      this.$router.push({
        name: 'storeCart',
        params: {
          id: id
        }
      })
    }
  },
}
</script>
<style lang="scss">
p {
  margin: 0 !important;
  padding: 0;
}

.store-body {
  height: 250px;
  width: 97%;
  max-width: 400px;
  border-radius: 10px;
  border: 1px #f1f1f1 solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 5px;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.2);
  box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.2);
  padding-bottom: 10px;

  .assert-body {
    height: 65%;
    width: 100%;
    position: relative;

    .store-logo {
      -webkit-box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.4);
      box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.4);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      bottom: -20px;
      left: 10px;
      background: rgb(255, 255, 255);
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      overflow: hidden;

      .logo_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .store-banner {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
      position: relative;
      //background-image: url('/src/assets/img/placeholders/store-placeholder.jpg');
      overflow: hidden;

      .banner-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .store-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px 10px 0 0;
        backdrop-filter: blur(1px);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }

      .rating {
        background: rgba(12, 173, 105, 0.7);
        color: white;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 10px;
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
      }

      .store-favourite {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #fff;
        border-radius: 10px;

        .favourite {
          color: #000;

          font-size: 20px;
        }
      }
    }
  }


  .store-name {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    word-spacing: 0;
    color: #000;
  }

  .store-details {
    position: relative;
    padding: 20px 10px 0 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .store-address {
      .category {
        font-weight: 400;
        font-size: 16px;
        color: #808080;

        .category-icon {
          color: #CB997E;
        }
      }

      .address {
        font-weight: 200;
        font-size: 16px;
        color: #979797;

        .category-icon {
          color: #CB997E;
        }
      }
    }

    .rating-icon {
      background-color: #909090;
    }
  }
}
</style>

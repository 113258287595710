<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    title: 'ABC Online store',
    titleTemplate: '%s | your one stop online marketplace',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: 'your one stop online marketplace.'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  beforeMount() {
    this.getID();
    this.$store.dispatch('getStoreTags');
    this.$store.dispatch('getAllStore');
  },
  methods: {
    getID() {
      if (!localStorage.getItem('sessionID')) {
        this.$store.dispatch('getSessionID')
      }
      this.$store.dispatch('getSessionCarts');
    }
  }
}
</script>
<style lang="scss">
@import "/src/assets/css/styles.scss";

#app {
  padding-bottom: 3.5em;
}

</style>

<template>
    <div class="header-banner" :style="bkg" v-once>

        <span @click="back" class="icon-btn back-btn">
            <font-awesome-icon icon="fa-solid fa-arrow-left"/>
        </span>
        <br>
        <div class="banner-text">
            <h1 class="banner-title">Want it?</h1>
            <h2 class="banner-title2">Get it</h2>
            <h4 class="banner-subtitle"> at your online marketplace</h4>
        </div>

    </div>
</template>

<script>
    export default {
        name: "BasicHeader",
        created() {
        },
        props:['image'],
        computed:{
          bkg(){
              return { "background": 'url('+this.image+'),#242D3A' };
          }
        },
        methods:{
            back() {
                if(this.$router.currentRoute.name === 'Profile'){
                    this.$router.push({ name : 'Home' })
                }else{
                    this.$router.back();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .header-banner {
        position: relative;
        background-position: center right !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        color: #fff;
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner-text {
            margin-left: 10px;
            width: fit-content;
            background: rgba(33, 45, 61, 0.7);
            -webkit-backdrop-filter: blur(8px);  /* Safari 9+ */
            backdrop-filter: blur(2px);
            padding: 5px 10px 5px 0;
            border: 4px solid rgba(33, 45, 61, 0.5);
            border-radius: 10px;

            .banner-title {
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 30px;
            }
            .banner-title2{
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                font-size: 25px;
            }

            .banner-subtitle {
                font-family: 'Inter', sans-serif;
                font-size: 20px;
                font-weight: 300;
            }
        }

        .search-box {
            position: absolute;
            bottom: -25px;
            left: 0;
            right: 0;
            display: flex;
            width: 100vw;
            justify-content: center;

            .search-field {
                width: 90%;
                max-width: 600px;
            }
        }
    }
    .back-btn{
        width:43px;
        height:43px;
        border: 2px solid #f5f5f5;
        border-radius: 50%;
        color:black;
        text-align:center;
        text-decoration:none;
        background: white;
        font-size:20px;
        font-weight:bold;
        padding: 5px;
    }
</style>
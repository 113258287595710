import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {Storage} from "@/services/Storage";

Vue.use(Vuex);
const parseKey = (store) => {
    return `img::${store}`.toLowerCase();
}
export default {
    name: "ShopService",
    state: {
        currentStore: undefined,
        store: {},
        stores: [],
        featured_stores: [],
        store_banner_placeholder: require('/src/assets/img/placeholders/store-placeholder.jpg'),
        store_banners: new Map(),
        store_logos: new Map(),
        currentStoreStock: [],
    },
    getters: {
        getCurrentStore: (state) => state.currentStore,
        getStores: (state) => state.stores,
        getFeaturedStores: (state) => state.featured_stores,
        getCurrentStoreStock: (state) => state.currentStoreStock,
    },
    mutations: {
        setCurrentStoreStock: (state, data) => state.currentStoreStock = data,
        setCurrentStore: (state, data) => {
            if (data) {
                return Storage.setCurrentStore(data).then(() => state.currentStore = data);
            }
            return Storage.getCurrentStore().then(res => state.currentStore = res);
        },
        setFeaturedStores: (state, data) => {
            if (data) {
                return Storage.setFeaturedStores(data).then(() => state.featured_stores = data)
            }
            return Storage.getFeaturedStores().then(res => state.featured_stores = res);
        },
        setStores: (state, data) => {
            if (data) {
                return Storage.setStores(data).then(() => state.stores = data);
            }
            return Storage.getAllStores().then(res => state.stores = res);
        },
        addBanner: (state, payload) => {
            if (!state.store_banners.has(payload.key)) {
                state.store_banners.set(payload.key, payload.image)
            }
        }
    },
    actions: {
        getStoreBanner: (context, id) => {
            const key = parseKey(id)
            const map = context.state.store_banners
            if (map.has(key)) return Promise.resolve(context.state.store_banners.get(key));
            if (id === 'undefined') {
                return Promise.reject();
            }
            return axios.get(ApiServer + 'store/getbanner/' + id).then((res) => {
                if (res.status === 204) {
                    return context.state.store_banner_placeholder;
                } else {
                    let image = res.data
                    context.commit("addBanner", {'key': key, image: image})
                    return image
                }
            }).catch(err => {
                console.error(err)
                return context.state.store_banner_placeholder;
            })
        },
        getStoreLogo: ({}, id) => {
            return axios.get(ApiServer + 'store/getlogo/' + id)
        },
        getAllStore: (context) => {
            return axios.get(ApiServer + `store/getAll`)
                .then(({data}) => {
                    context.commit("setStores", data)
                    context.commit("setFeaturedStores", data.filter(s => s.Featured === true));
                    let axiosArray = []
                    for (let x = 0; x < data.length; x++) {
                        let newPromise = context.dispatch('getStoreImages', data[x].id);
                        axiosArray.push(newPromise)
                    }
                    return axios.all(axiosArray);
                })
                .catch((err) => {
                    responses.throwErr(err).catch()
                });
        },
        getFeaturedStores: (context) => {
            return axios.get(ApiServer + 'store/featured')
                .then(({data}) => context.commit("setFeaturedStores", data))
                .catch((err) => responses.throwErr(err));
        },
        getStoreByID: (context, id) => {
            return Storage.getStoreByID(id)
                .then((res) => res);
        },

        getStoreByTag(context, tag) {
            return axios.get(ApiServer + `store_tags/getByStoreTag/${tag}`)
                .then(({data}) => data)
                .catch(err => responses.throwErr(err))
        },


        getStoreStock(context) {
            return Storage.getCurrentStore().then(store => {
                return axios.get(serverApi + `stock/${store.storeLocation.database}`).then(({data}) => {
                    const stockSettings = data.map(s => {
                        let set = s.StockSetting
                        if (set) {
                            return {
                                plu: s.PLU,
                                cookingLink: set.CookingLink
                            }
                        } else {
                            return {
                                plu: s.PLU,
                                cookingLink: false
                            }
                        }
                    })
                    const stock = data.map(s => {
                        delete s.CreatedDate
                        delete s.Image
                        delete s.Price1
                        delete s.Price2
                        delete s.Price3
                        delete s.Price4
                        delete s.Price5
                        delete s.args
                        delete s.where_between
                        delete s.StockSetting
                        return s;
                    });
                    return context.commit('setCurrentStoreStock', stock);
                }).catch(err => responses.throwErr(err))
            })
        },
        clearStore: (context) => {
            context.commit('setCurrentStore', undefined);
            context.commit('setCurrentCart', undefined);
            context.commit('setCurrentStoreStock', []);
            context.commit('setCurrentStoreCategories', [])
        }
    }
}

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
export default {
    name: "Images",
    state: {
        images: [],
    },
    getters: {
        getImageByStore: (state) => (storeID) => state.images.find(s => s.storeID === storeID),
    },
    mutations: {
        setImage: (state, payload) => {
            let storeImage = state.images.find(s => s.storeID === payload.storeID);
            if (storeImage) {
                state.images.map(s => {
                    if (s.storeID === payload.storeID) {
                        return payload;
                    }
                    return s;
                });
            } else {
                state.images.push(payload);
            }
        }
    },
    actions: {
        getStoreImages(context , storeID){
            let obj = {
                storeID : storeID,
                banner : undefined,
                logo : undefined
            }
            return axios.all([
                axios.get($utils.getStoreBannerUrl(storeID))
                    .then(res=>{
                        if (res.status === 204){
                            obj.banner = require('/src/assets/img/placeholders/store-banner-placeholder.webp');
                        }else {
                            obj.banner = `data:image/png;base64,${res.data}`
                        }}),
                axios.get($utils.getStoreLogoUrl(storeID))
                    .then(res=>{
                        if (res.status === 204){
                            obj.logo = require('/src/assets/img/placeholders/store-banner-placeholder.webp');
                        }else {
                            obj.logo = `data:image/png;base64,${res.data}`
                        }}),
            ]).finally(() => context.commit('setImage',obj));
        },
    },
}

<template>
    <div>
        <HomeHeader/>
        <Tags/>
        <FeaturedStores :horizontal="false"/>
        <BottomNavigation/>
    </div>
</template>

<script>
    import FeaturedStores from "@/components/stores/FeaturedStores";
    import HomeHeader from "@/components/Layout/HomeHeader";
    import BottomNavigation from "@/components/Layout/BottomNavigation";
    import Tags from "@/components/tags/Tags";

    export default {
        name: 'HomeView',
        components: {Tags, BottomNavigation,HomeHeader, FeaturedStores},
    }
</script>
<style>
    .item2 {
        width: 240px;
        padding: 32px 32px 24px 32px;
        background: #f1f1f1;
        margin-right: 8px;
    }

    .content {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        padding-top: 60%;
    }

    .title {
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }

    .pagination {
        margin-top: 12px;
        display: flex;
        justify-content: center;
    }

    .dot {
        padding: 4px;
        cursor: pointer;
    }

    .dot > div {
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background: #33333350;
    }

    .dot:hover > div {
        border: 1px solid black;
        background: white;
    }

    .dot.current > div {
        border: 3px solid black;
        background: white;
    }

    .btn-left, .btn-right {
        padding: 6px;
        height: 100%;
    }

    .btn-left {
        background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
    }

    .btn-right {
        background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
    }

    main {
        padding: 24px;
    }

    @media (min-width: 768px) {
        main {
            padding: 48px;
        }
    }

    article {
        margin-top: 24px;
    }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/HomeView'
import Layout from '@/components/Layout/Layout'
import AllCarts from "@/views/cart/AllCarts";
import LoginView from "@/views/User/LoginView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: Home,
                name: 'Home'
            },

            {
                path: '/store/:id',
                component: () => import(/* webpackChunkName: "Shop" */ '@/views/stores/StoreDetailsView'),
                name: 'Shop',
                props: true,
            },

            {
                path: '/search',
                component: () => import (/* webpackChunkName: "Stores" */ "@/views/stores/StoreSearchView"),
                props: true,
                name: 'Stores'
            },

            {
                path: '/cart/:id',
                component: () => import(/* webpackChunkName: "storeCart" */ '@/views/cart/storeCart'),
                name: 'storeCart'
            },

            {
                path: '/allCarts',
                component: AllCarts,
                name: 'AllCarts'
            },

            {
                path: '/category/:id',
                component: () => import(/* webpackChunkName: "CategorySearchView" */ "@/views/categories/CategorySearchView"),
                name: 'CategorySearchView',
                props: true
            },

            {
                path: '/login',
                component:LoginView,
                name: 'Login',
            },

            {
                path: '/register',
                component : () => import(/* webpackChunkName: "Register" */ "@/views/User/RegisterView"),
                name: 'Register',
            },

            {
                path: '/profile',
                //component: ProfileView,
                component : () => import(/* webpackChunkName: "Profile" */ "@/views/User/ProfileView"),
                name: 'Profile',
            },

            {
                path: '/email-verification',
                component : () => import(/* webpackChunkName: "EmailVerification" */ "@/views/User/EmailVerificationView"),
                name: 'EmailVerification',
            },


            {
                path: '/check-email',
                name: 'CheckEmail',
                component : () => import(/* webpackChunkName: "CheckEmail" */ "@/views/User/ForgotPass/CheckEmailView"),
            },
            {
                path: '/check-otp',
                name: 'CheckOTP',
                component: () => import(/* webpackChunkName: "CheckOTP" */ "@/views/User/ForgotPass/CheckOTPView")
            },
            {
                path: '/new-password',
                name: 'NewPassword',
                component: () => import(/* webpackChunkName: "NewPassword" */ "@/views/User/ForgotPass/NewPassword")
            },


            {
                path: '/*',
                component: Home,
                name: 'Default'
            },
        ]

    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router

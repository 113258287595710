<template>
    <v-bottom-navigation
            class="bottom_navigation"
            color="teal"
            grow
    >
        <v-btn to="/" value="Home">
            <span>Home</span>
            <font-awesome-icon class="search-icon" icon="fa-solid fa-house"/>
        </v-btn>

        <v-btn to="/search" value="Stores">
            <span>Browse</span>
            <font-awesome-icon class="search-icon" icon="fa-solid fa-shop"/>
        </v-btn>
        <v-btn class="cart-button" value="storeCart" to="/allCarts">
            <span class="badge">{{ cartCount }}</span>
            <span>Carts</span>
            <font-awesome-icon class="search-icon" icon="fa-solid fa-cart-shopping"/>
        </v-btn>
        <v-btn value="CategorySearchView" @click="checkUser">
            <span>Account</span>
            <font-awesome-icon class="search-icon" icon="fa-solid fa-user"/>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
    export default {
        name: "BottomNavigation",
        computed: {
            cartCount() {
                return this.$store.getters.getCartCount;
            },
        },
        methods: {
            checkUser() {
                let user = localStorage.getItem("user");
                if (!user) {
                    this.$router.push({name: 'Login'})
                } else {
                    this.$router.push({name: 'Profile'})
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cart-button {
        position: relative;

        .badge {
            position: absolute;
            top: 2px;
            right: 3px;
            width: 24px;
            height: 24px;
            padding: 2px;
            text-align: center;
            color: white;
            font-weight: 600;
            border: 2px solid white;
            border-radius: 50%;
            background: #212D3D;
        }
    }

    .bottom_navigation {
        position: fixed;
    }

    a {
        color: #a5a5a5;
        text-decoration: none;
        padding-left: 15px;
        text-align: center;

        &:hover, &.router-link-exact-active {
            background: #000000;
            padding: 15px;
            color: #58db51;
            font-weight: 700;
            border-radius: 25px;
        }
    }

    .search-icon {
        font-size: 20px;
    }
</style>

export const responses = {
    throwErr(err){
        return Toast.fire({
            icon: 'error',
            title: err
        });
    },

    showSuccess(msg){
        return Toast.fire({
            icon: 'success',
            title: msg
        });
    },

    showInfo(msg){
        return Toast.fire({
            icon: 'info',
            title: msg
        });
    },

    showWarning(msg){
        return Toast.fire({
            icon: 'warning',
            title: msg
        });
    },
}

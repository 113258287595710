<template>
<!--  <vue-drawer-layout
      ref="drawerLayout"
      :enable="false"
      @mask-click="closeDrawer"
      :content-drawable="true"
  >
    <NavigationDrawer slot="drawer"/>-->
    <div slot="content" id="inspire">
<!--      <NavigationBar :method="toggleDrawer"></NavigationBar>-->
      <router-view/>
    </div>
<!--  </vue-drawer-layout>-->

</template>
<script>
    import NavigationDrawer from "@/components/Layout/NavigationDrawer";

    export default {
        components: {NavigationDrawer},
        computed: {
            items() {
                return this.$store.state.categories
            },
        },
        data() {
            return {
                activeBtn: 1,
            }
        },
  methods: {
    openTag(tag) {
      this.$router.push({
        name: 'Stores', query: {'tag': tag}
      })
    },
    toggleDrawer() {
      this.$refs.drawerLayout.toggle();
    }, handleSlideStart() {
      console.info('slide-start');
    },
    handleSlideEnd(visible) {
      console.info('slide-end', visible);
    },

  }
}
</script>

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

function parseKey(store, plu) {
    return `img::${store}::${plu}`.toLowerCase();
}

export default {
    name: "ShopService",
    state: {
        image_store: new Map(),
    },
    getters: {
        getCachedProductImage: (state)=>(store, plu) => {
            let img = state.image_store.get(parseKey(store, plu))
            if (img === undefined || img === null) {
                img = require('/src/assets/img/placeholders/product-placeholder.jpg');
            }
            return img;
        }
    },
    mutations: {
        addImage: (state,payload) => {
            if (!state.image_store.has(payload.key)) {
                state.image_store.set(payload.key, payload.image)
            }
        }
    },
    actions: {
        getProductImage: ({state,commit}, data) => {
            const key = parseKey(data.store, data.id)
            /*if (state.image_store.has(key)) {
                return Promise.resolve(state.image_store.get(key))
            } else return axios.get(`${ApiServer}stock/${data.store}/getImage/${data.id}`)
                .then((res) => {
                    let image;
                    if (res.status === 204) {
                        image= require('/src/assets/img/placeholders/product-placeholder.jpg')
                    } else {
                        image = `data:image/png;base64,${res.data}`
                    }
                    commit("addImage",{ 'key':key,'image':image})
                    return image;
                }).catch(err => {
                    console.error(err)
                    return require('/src/assets/img/placeholders/product-placeholder.jpg')
                })*/
        },
        getProductByPlu(context,data){
            return axios.get(ApiServer+`stock/${data.DB}/${data.PLU}`)
                .then(({data})=>{
                    return data;
                }).catch((err) => {
                    responses.throwErr(err);
                });
        },

        searchStock(context,data){
            return axios.post(ApiServer + `stock/${data.DB}/search`, data.search)
                .then((items) => {
                    return items.data;
                }).catch(err => {
                    responses.throwErr(err);
                })
        },
        getByCategory(context,data){
            return axios.post(`${ApiServer}stock/${data.DB}/getByCategory`,data.search)
                .then((items) => {
                    return items.data;
                }).catch(err => {
                    responses.throwErr(err);
                })
        },
    }
}

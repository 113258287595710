<template>
    <div v-once>
        <div class="header-banner">

            <div class="banner-text">
                <h1 class="banner-title">Want it?</h1>
                <h2 class="banner-title2">Get it</h2>
                <h4 class="banner-subtitle"> at your online marketplace</h4>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeHeader",
    }
</script>

<style lang="scss" scoped>
    .header-banner {
        position: relative;
        background: url("../../assets/img/home/abc_banner-min.png"), #212D3D;
        background-position: center right !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        color: #fff;
        height: 41vh;
        width: 100vw;
        margin-bottom: 30px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner-text {
            margin-left: 10px;
            width: fit-content;
            background: rgba(33, 45, 61, 0.7);
            -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
            backdrop-filter: blur(2px);
            padding: 5px 10px 5px 0;
            border: 4px solid rgba(33, 45, 61, 0.5);
            border-radius: 10px;

            .banner-title {
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 30px;
            }

            .banner-title2 {
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                font-size: 25px;
            }

            .banner-subtitle {
                font-family: 'Inter', sans-serif;
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
</style>

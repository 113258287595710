<template>
  <div class="product-list-item" @click="$emit('click')">
    <div class="product-image" :style="storelogo"></div>
    <div class="product-list-details">
      <h4>{{ cart.store.StoreName }}</h4>
      <font-awesome-icon icon="fa-solid fa-chevron-right" style="float: right"/>
      <p>{{ cart.item_count }} Item(s) &bullet; {{ $utils.formatMoney(cart.cart_total) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoresCarts",
  props: {
    cart: {type: Object, required: true},
  },
  computed: {
    storelogo() {
      console.log(this.cart)
      return {"background": 'url(' + this.$utils.getStoreLogoUrl(this.cart.store.id) + '),#242D3A'};
    }
  },
}
</script>

<style lang="scss" scoped>
.product-list-item {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 22% 75%;
  height: 77px;
  max-width: 400px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e2e8f0;

  .product-image {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .product-list-details {
    width: 100%;
    height: 100%;
  }
}
</style>
import { render, staticRenderFns } from "./BottomNavigation.vue?vue&type=template&id=bb6e77ee&scoped=true&"
import script from "./BottomNavigation.vue?vue&type=script&lang=js&"
export * from "./BottomNavigation.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavigation.vue?vue&type=style&index=0&id=bb6e77ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb6e77ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBottomNavigation,VBtn})

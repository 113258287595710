<template>
  <div class="listing" v-if="hasFeaturedStores">
    <div class="listing-header">
      <h3 class="listing-title">Featured Stores</h3>
    </div>
    <vue-horizontal v-if="horizontal" :displacement="0.3"
                    class="listing-body horizontal"
                    snap="none" :button="false">
      <ShopItem v-for="store in featuredStores" :store="store" :key="store.id"/>
    </vue-horizontal>

    <div v-else class="listing-body featured-stores">
      <ShopItem v-for="store in featuredStores" :store="store" :key="store.id"/>
    </div>
  </div>
</template>

<script>
import ShopItem from "@/components/stores/StoreListItem";
import VueHorizontal from "vue-horizontal";

export default {
  name: "FeaturedStores",
  components: {ShopItem, VueHorizontal},
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    featuredStores() {
      return this.$store.getters.getFeaturedStores;
    },
    hasFeaturedStores() {
      return this.featuredStores && this.featuredStores.length > 0
    }
  },
}
</script>

<style scoped>
.featured-stores {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

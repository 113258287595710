import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify.js' // path to vuetify export
import VueHorizontal from "vue-horizontal"
import VueMeta from 'vue-meta'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import NwImg from 'nw-img-vue';
import './assets/fonts/icons'
import './services/GlobalVariable'

Vue.use(NwImg);
Vue.use(VueMeta)
Vue.component(VueHorizontal);
Vue.use(VueVirtualScroller)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

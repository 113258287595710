import {Device} from "./Device";
import {responses} from "@/utils/responses";
import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "@/utils/utils";
import qs_stringify from "qs-stringify";


window.Stringify = qs_stringify;
window.Device = Device;
window.responses = responses;
window.Swal = Swal;
window.$utils = utils;
Vue.prototype.$utils = utils;


window.ApiServer = `https://shop.abc.co.zm:8443/ABC_API/`;
window.serverApi = `https://shop.abc.co.zm:8443/OnlineSalesServer/`

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
window.Toast = Toast;
const EventBus = new Vue();
window.EventBus = EventBus

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1,
    lazyComponent:true,
    observer:true,
    loading : require('../assets/img/loader.gif'),
    error:require('../assets/img/placeholders/store-banner-placeholder.png'),
})

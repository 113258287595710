import Vue from 'vue'
import Vuex from 'vuex'
import category_service from "@/store/modules/categories/categoryservice";
import shop_service from "@/store/modules/stores/shopservice";
import stock_provider from "@/store/modules/stock/stockprovider";
import cart_store from "@/store/modules/cart/cartstore";
import stock_categories from "@/store/modules/stock/stock_categories";
import images from "@/store/modules/image-handler/images";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        category_service,
        shop_service,
        stock_provider,
        cart_store,
        stock_categories,
        images,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getSessionID: (context) => {
            return Device.getDeviceId()
                .then(() => context.dispatch('getSessionCarts').catch());
        },

        validateCurrentStore(context, id) {
            context.dispatch("getStoreByID", id)
                .then(res => {
                    context.commit("setCurrentCategory")
                    context.commit("setCurrentStore", res)
                })
            return true;
        }
    },
})

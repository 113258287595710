<template>
    <div>
        <form @submit.prevent="login">
            <div class="container">
                <label for="uname"><b>Email Address / Phone Number</b></label>
                <input type="text" placeholder="Enter Email Address / Phone Number" id="uname" v-model="username"
                       required>

                <label for="psw"><b>Password</b></label>
                <input :type="type" placeholder="Enter Password" id="psw" v-model="password" required>
                <label>
                    <input type="checkbox" @click="show_password">
                    Show Password
                </label>

                <button type="submit">Login</button>
            </div>

            <div class="container" style="background-color:#f1f1f1">
                <span class="psw text-center">Don't have an account? <a @click="goToReg">Sign up</a></span><br>
                <span class="psw text-center"><a @click="forgotpassword">Forgot password?</a></span>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                username: '',
                password: '',
                type: 'password',
            }
        },
        methods: {
            show_password() {
                if (this.type === 'password') {
                    this.type = 'text'
                } else {
                    this.type = 'password'
                }
            },
            goToReg() {
                this.$router.push({name: 'Register'})
            },
            login() {
                this.$router.push({name: 'Profile'})
            },
            forgotpassword(){
                this.$router.push({name: 'CheckEmail'})
            },
        }
    }
</script>

<style scoped>
    /* Bordered form */
    form {
        border: 3px solid #f1f1f1;
    }

    /* Full-width inputs */
    input[type=text], input[type=password] {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    /* Set a style for all buttons */
    button {
        background: #212D3D;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        width: 100%;
    }

    /* Add a hover effect for buttons */
    button:hover {
        opacity: 0.8;
    }

    /* Add padding to containers */
    .container {
        padding: 16px;
    }

    /* The "Forgot password" text */
    span.psw {
        text-align: center;
        padding-top: 16px;
        padding-left: 10px;
    }

    /* Change styles for span and cancel button on extra small screens */
    @media screen and (max-width: 300px) {
        span.psw {
            display: block;
            float: none;
        }
    }
</style>
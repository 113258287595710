<template>
  <div>

    <BasicHeader :image="require('/src/assets/img/home/abc_banner-min.png')"/>
    <div class="container">
      <h2>Carts</h2>
      <StoresCarts :cart="cart" :key="cart.id" v-for="cart in carts" @click="openCart(cart.store.id)"/>
    </div>
    <BottomNavigation/>
  </div>
</template>

<script>
import BasicHeader from "@/components/Layout/BasicHeader";
import BottomNavigation from "@/components/Layout/BottomNavigation";
import StoresCarts from "@/components/stores/cart/StoresCarts";

export default {
  name: "AllCarts",
  components: {StoresCarts, BottomNavigation, BasicHeader},
  beforeMount() {
    window.scrollTo(0, 0)
  },
  computed: {
    carts() {
      return this.$store.getters.getAllCarts;
    }
  },
  methods: {
    openCart(id) {
      this.$store.commit("setCurrentStore", this.store)
      this.$router.push({
        name: 'storeCart',
        params: {
          id: id
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
import Vue from "vue";
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
//import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const opts = {theme: {
        themes: {
            light: {
                primary: '#212D3D',
                secondary: '#212D3D',
                accent: '#3AE3D6',
                error: '#f44336',
                info: '#212D3D',
                success: '#7a904a',
                warning: '#f78012',
            }
        },
    },};

export default new Vuetify(opts)

import {Storage as Store} from '@capacitor/storage';

export const ALL_STORES = 'all_stores'
export const FEATURED_STORES = 'featured_stores'
export const CURRENT_STORE = 'current_store'
export const CARTS = 'carts'
export const STORE_TAGS = 'store_tags'
export const CURRENT_CATEGORY = 'current_category'
export const Storage = {

    setStores: async (stores) => {
        await Store.set({
            key: ALL_STORES, value: JSON.stringify(stores)
        })
    },

    getAllStores: async () => {
        const value = await Store.get({
            key: ALL_STORES
        })
        return JSON.parse(value.value)
    },

    getStoreByID: async (id) => {
        const value = await Store.get({
            key: ALL_STORES
        })
        let stores = JSON.parse(value.value);
        return stores.find(x => x.id === id);
    },

    setFeaturedStores: async (stores) => {
        await Store.set({
            key: FEATURED_STORES, value: JSON.stringify(stores)
        })
    },

    getFeaturedStores: async () => {
        const value = await Store.get({
            key: FEATURED_STORES
        })
        return JSON.parse(value.value)
    },

    setCurrentStore: async (stores) => {
        await Store.set({
            key: CURRENT_STORE, value: JSON.stringify(stores)
        })
    },

    getCurrentStore: async () => {
        const value = await Store.get({
            key: CURRENT_STORE
        })
        return JSON.parse(value.value)
    },

    getAllCarts : async () =>{
        const value = await Store.get({
            key: CARTS
        })
        return JSON.parse(value.value)
    },

    setCarts: async (payload) => {
        await Store.set({
            key: CARTS, value: JSON.stringify(payload)
        })
    },

    setTags : async (payload) => {
        await Store.set({
            key: STORE_TAGS, value: JSON.stringify(payload)
        })
    },

    getAllTags :async ()=>{
        const value = await Store.get({
            key: STORE_TAGS
        })
        return JSON.parse(value.value)
    },

    setCurrentCategory : async (payload) => {
        await Store.set({
            key: CURRENT_CATEGORY,
            value: JSON.stringify(payload)
        })
    },

    getCurrentStoreCategory :async ()=>{
        const value = await Store.get({
            key: CURRENT_CATEGORY
        });
        return JSON.parse(value.value)
    },

    clear: async () => Store.clear(),
}

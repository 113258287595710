<template>
    <div class="listing">
        <div class="listing-header">
            <h3 class="listing-title">Shop by category</h3>
            <a class="see-all" style="float: right;line-height: 0" @click="openStoreSearch">
                View All <font-awesome-icon icon="fa fa-chevron-right" />
            </a>
        </div>
        <vue-horizontal :displacement="0.3"
                        class="listing-body horizontal"
                        snap="none"
                        :button="buttons">
            <template v-if="buttons" v-slot:btn-prev>
                <svg class="btn-left" viewBox="0 0 24 24">
                    <path d="m9.8 12 5 5a1 1 0 1 1-1.4 1.4l-5.7-5.7a1 1 0 0 1 0-1.4l5.7-5.7a1 1 0 0 1 1.4 1.4l-5 5z"/>
                </svg>
            </template>
            <template v-if="buttons" v-slot:btn-next>
                <svg class="btn-right" viewBox="0 0 24 24">
                    <path d="m14.3 12.1-5-5a1 1 0 0 1 1.4-1.4l5.7 5.7a1 1 0 0 1 0 1.4l-5.7 5.7a1 1 0 0 1-1.4-1.4l5-5z"/>
                </svg>
            </template>
            <CircleTagItem v-for="tag in tags" :key="tag.id" :tag="tag"/>
        </vue-horizontal>
    </div>
</template>
<script>
    import VueHorizontal from "vue-horizontal";
    import CircleTagItem from "@/components/tags/CircleTagItem";
    export default {
        name: "Tags",
        components: {
            VueHorizontal,
            CircleTagItem
        },
        computed: {
            categories() {
                return this.$store.state.categories
            },
            tags() {
                return this.$store.getters.getStoreTags;
            }
        },
        data() {
            return {
                buttons: false,
            }
        },
        methods:{
            openStoreSearch() {
                this.$router.push({ name : 'Stores'})
            }
        }

    }
</script>


<style lang="scss">
    @media (max-width: 767.98px) {
        .item {
            width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count));
            padding: 0 calc(var(--gap) / 2);
        }

        .item:first-child {
            width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) - (var(--gap) / 2));
            padding-left: var(--margin);
        }

        .item:last-child {
            width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) - (var(--gap) / 2));
            padding-right: var(--margin);
        }

        .item:only-child {
            width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) * 2 - var(--gap));
        }

        .horizontal {
            margin: 0 calc(var(--margin) * -1);
        }

        .horizontal > > > .v-hl-container {
            scroll-padding: 0 calc(var(--margin) - (var(--gap) / 2));
        }

        .horizontal > > > .v-hl-btn {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .item {
            width: calc((100% - ((var(--count) - 1) * var(--gap))) / var(--count));
            margin-right: var(--gap);
        }
    }

    .btn-left, .btn-right {
        padding: 12px;
        height: 100%;
    }

    .btn-left {
        background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
    }

    .btn-right {
        background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
    }

</style>

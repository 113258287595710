import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {Storage} from "@/services/Storage";

Vue.use(Vuex);
export default {
    name: "CategoryService",
    state: {
        categories: [],
        tags: [],
        current_store_categories : [],
    },
    getters: {
        getStoreTags: (state) => state.tags,
        getCurrentStoreCategories: (state) => state.current_store_categories,
    },
    mutations: {
        setTags: (state, data) => {
            if (data){
                return Storage.setCarts(data).then(()=>state.tags = data)
            }
            return Storage.getAllCarts().then((res) => state.tags = res);
        },
        setCurrentStoreCategories : (state,data) =>state.current_store_categories = data,
    },

    actions: {
        getStoreTags: (context) => {
            return axios.get(ApiServer + 'store_tags/getAllUniqueTags')
                .then(({data}) => context.commit("setTags", data))
                .catch((err) => responses.throwErr(err));
        },

        getAllCategories : (context) => {
            return Storage.getCurrentStore().then(store => {
                return axios.get(serverApi + `category/${store.storeLocation.database}/getAsTree`)
                    .then(({data})=>context.commit('setCurrentStoreCategories',data.filter(t => t.hidden === false)))
                    .catch((err)=> responses.throwErr(err));
            })
        },
    }
}

<template>
    <div>
        <BasicHeader :image="require('/src/assets/img/home/abc_banner-min.png')"/>
        <login/>
    </div>
</template>

<script>
    import Login from "@/components/User/login";
    import BasicHeader from "@/components/Layout/BasicHeader";

    export default {
        name: "LoginView",
        components: {Login, BasicHeader}
    }
</script>

<style scoped>

</style>
import Vue from "vue";
import Vuex from "vuex";
import {Storage} from "@/services/Storage";

Vue.use(Vuex);
export default {
    name: "CategoryService",
    state:{
        current_stock_category : null,
        current_sub_category : null,
        current_sub_category2 : null,
        unCategorisedProducts:[],
    },
    getters: {
        getUnCategorisedProducts: (state)=>{
            return state.unCategorisedProducts;
        },
        getCurrentCategory: (state) =>{
            return state.current_stock_category;
        },

        getCurrentSubCat: (state) =>{
            return state.current_sub_category;
        },

        getCurrentSubCat2: (state) =>{
            return state.current_sub_category2;
        }
    },
    mutations:{
        setUnCategorisedProducts: (state,data)=>state.unCategorisedProducts = data,

        setCurrentCategory: (state,data) => {
            if (data){
                state.current_stock_category = data;
                return Storage.setCurrentCategory(data)
                    .then(()=>state.current_stock_category = data)
            }
            return Storage.getCurrentStoreCategory()
                .then((res) => {
                    state.current_stock_category = res
                });
        },

        setCurrentSubCat: (state,data) =>state.current_sub_category = data,

        setCurrentSubCat2: (state,data) => state.current_sub_category2 = data,
    },
}

import {Device as Gadget} from '@capacitor/device';

export const Device = {
    logDeviceInfo: async () => {
        const info = await Gadget.getInfo();
    },
    getDeviceId: async () => {
        return await Gadget.getId().then(res=>{
            localStorage.setItem('sessionID', res.uuid)
        });
    },
    logDeviceBatteryInfo: async () => {
        const info = await Gadget.getBatteryInfo();
    }
}
